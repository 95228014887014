.react-daterange-picker__wrapper {
    border-radius: 5px !important;
    padding: 1px 4px !important;
    border: 1px solid #828282 !important;
    color: #828282 !important;
}
.react-daterange-picker__calendar-button__icon {
    stroke: #828282 !important;
}

input[name='day'] {
  pointer-events: none !important;
}
input[name='month'] {
  pointer-events: none !important;
}
input[name='year'] {
  pointer-events: none !important;
}
